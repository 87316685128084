<template>
  <v-dialog :fullscreen="$store.state.isMobile" max-width="600" style="z-index:9999;"
            v-model="dialog" @click:outside="close"
  >
    <div class="popup__content">
      <v-btn class="popup__close" small icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h5 class="popup__title">{{title}}</h5>
      <div class="popup__actions">
        <v-btn class="popup__action btn btn_primary"
               color="primary"
               depressed
               @click="confirm"
        >
          Open Editor
        </v-btn>
        <v-btn class="popup__action btn btn_primary"
               color="primary"
               depressed
               @click="save"
        >
          Save
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "PopupCrop",
  props: {
    title: String
  },
  data: () => ({
    dialog: false,
    file:null
  }),
  methods: {
    open(file) {
      this.file = file;
      this.dialog = true;
    },
    close(){
      this.dialog = false;
      this.$emit('close');
    },
    save() {
      this.$emit('save', this.file);
      this.dialog = false;
    },
    confirm() {
      this.$emit('confirm', this.file);
      this.dialog = false;
    }
  }
}
</script>

<style scoped lang="scss">

.popup__actions {
  display: flex;
  margin: 0 -10px;
}
.popup__action {
  width: calc(50% - 20px);
  margin: 0 10px;
}
</style>
